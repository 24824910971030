import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/code/src/components/layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Rows = makeShortcode("Rows");
const PlainColumn = makeShortcode("PlainColumn");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Rows mdxType="Rows">
  <PlainColumn mdxType="PlainColumn">
    Seit der Gründung 2004 haben wir uns bei Hitabis Ihren Projekten
    verschrieben: Als Experten für Datenbanken, ECM und Integration von
    IT-Systemen und Maschinen wurde das Unternehmen von fünf IT-Experten und
    ehemaligen Oracle-Mitarbeitern gegründet. Anfangs erstellten
    wir hauptsächlich individuelle datenbankbasierte Systemlösungen für
    Industriekunden. Aufgrund der Zufriedenheit unserer Auftragnehmer und einer
    guten Reputation beauftragten immer mehr Organisationen und Unternehmen die
    Hitabis GmbH für die Konzeption und Entwicklung kundenspezifischer
    IT-Systeme.
  </PlainColumn>
  <PlainColumn mdxType="PlainColumn">
    Das Hitabis Technologie Portfolio umfasst den gesamten klassischen wie
    modernen Softwareentwicklungsprozess, eine prozessübergreifende IT-Beratung
    und Betreuung laufender Prozesse. Mit professionellem Know-how und höchstem
    Qualitätsanspruch realisieren wir mehrschichtige und verteilte
    Architekturen, die bei Bedarf durch Apps für Tablet-Computer und Smartphones
    ergänzt werden können. Die von Hitabis durchgeführten IT-Projekte berühren
    dabei oft sensible Bereiche von Unternehmen und Organisationen, weshalb
    Datenschutz und IT-Sicherheit für uns ein zentrales Thema sind.
  </PlainColumn>
    </Rows>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      